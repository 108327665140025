import axios from "axios";
import router from "@/router";
import store from "@/store";

const axiosInstance = axios.create({
    baseURL: "/api/v1",
});

axiosInstance.interceptors.response.use(
    function (response) {
        // isLogoutRequestSent = false;
        return response;
    },
    async function (error) {
        console.log(error);
        // whatever you want to do with the error
        if (error.response.status == 400) {
            console.log("400, Bad Request");
        } else if (error.response.status == 401) {
            console.log("401, Unauthorized/登入異常");
            router.push({ name: "login" });
        } else if (error.response.status == 410) {
            console.log("410");
            router.push({ name: "login" });
        } else if (error.response.status == 403) {
            console.log("403, Forbidden/權限不足");
        } else if (error.response.status == 409) {
            console.log("409, Conflict");
        } else if (error.response.status == 423) {
            console.log("423 group is not enabled.");
            store.commit("setSelectedGroupId", "");
            router.push({ name: "HomePage" });
        } else if (error.response.status == 500) {
            console.log("500");
        } else if (error.response.status == 502) {
            console.log("502");
        }
        return Promise.reject(error.response);
    }
);

// module.exports = axiosInstance;
export default axiosInstance;
