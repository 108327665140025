import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/LoginView.vue"),
    },
    {
        // 註冊邀請函  /user/complete-invite
        path: "/invites/signup",
        name: "invitesSignup",
        component: () => import("../views/Invites/InvitesSignupView.vue"),
    },
    {
        // 陌生人註冊
        path: "/register",
        name: "Register",
        component: () => import("../views/Register.vue"),
    },
    {
        path: "/",
        name: "Main",
        component: () => import("../components/MainLayout.vue"),
        children: [
            {
                path: "/home",
                name: "HomePage",
                component: () => import("../views/HomePage.vue"),
            },
            {
                path: "/UserInfo",
                name: "UserInfo",
                component: () => import("../views/UserInfo.vue"),
            },
            {
                path: "/domains",
                name: "Domains",
                component: () => import("../views/Domains/DomainListView.vue"),
                meta: {
                    requiredPermissionCheck: {
                        permissoinName: "domains",
                        action: "read",
                    },
                },
                children: [
                    {
                        path: ":domainId",
                        name: "DomainDetail",
                        component: () => import("../views/Domains/DomainEditor.vue"),
                    },
                ],
            },
            {
                path: "/groups",
                name: "Groups",
                component: () => import("../views/Groups/GroupsListView.vue"),
            },
            {
                path: "/groupSetting",
                name: "GroupSetting",
                component: () => import("../views/Groups/Setting/GroupSettingView.vue"),
            },
            {
                path: "/administrators",
                name: "Administrators",
                component: () => import("../views/Administrators/AdministratorsListView.vue"),
            },
            {
                // /group/group:id/users
                path: "/users",
                name: "Users",
                component: () => import("../views/Users/UserListView.vue"),
                meta: {
                    requiredPermissionCheck: {
                        permissoinName: "users",
                        action: "read",
                    },
                },
            },
            {
                // 獲取群組邀請列表  /groups/:groupId/invites
                path: "/invites",
                name: "Invites",
                component: () => import("../views/Invites/InvitesView.vue"),
                meta: {
                    requiredPermissionCheck: {
                        permissoinName: "invites",
                        action: "read",
                    },
                },
            },
            {
                // 獲取群組CDN廠商列表 /groups/:groupId/cdnproviders
                path: "/cdnproviders",
                name: "CdnProviders",
                component: () => import("../views/CdnProviders/CdnProvidersListView.vue"),
            },
            // {
            // 	// 獲取CDN群組線路列表 /groups/:groupId/cdnlines
            // 	path: "/cdnlines",
            // 	name: "CdnLines",
            // 	component: () => import("../views/CdnLines/CdnLinesListView.vue"),
            // 	meta: {
            // 		requiredPermissionCheck: {
            // 			permissoinName: "cdnlines",
            // 			action: "read",
            // 		},
            // 	},
            // },
            {
                path: "/roles",
                name: "Roles",
                component: () => import("../views/Roles/RoleListView.vue"),
                meta: {
                    requiredPermissionCheck: {
                        permissoinName: "roles",
                        action: "read",
                    },
                },
            },
            {
                path: "/logs",
                name: "SystemLog",
                component: () => import("../views/SystemLog.vue"),
            },
            {
                path: "/license/tokens",
                name: "LicenseTokens",
                component: () => import("../views/LicenseTokens.vue"),
            },
            {
                path: "/resourceUsage",
                name: "ResourceUsage",
                component: () => import("../views/ResourceUsage.vue"),
            },
            // {
            // 	path: "/test",
            // 	name: "test",
            // 	component: () => import("../views/Test.vue"),
            // },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFoundPage",
        component: () => import("../views/ErrorPages/NotFoundPage.vue"),
    },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
