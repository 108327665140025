<template>
	<br />
</template>

<script>
export default {
	props: ["show", "messageData", "resourceName"],
	watch: {
		show() {
			const noticeType =
				this.messageData.title === "Success" ? "success" : "error";

			this.$Notice[noticeType]({
				title: this.messageData.title,
				desc: this.messageData.message,
				duration: 8,
			});

			this.$router.push(`/${this.resourceName}`);
		},
	},
	data() {
		return {
			modal: false,
		};
	},
	methods: {},
};
</script>
