import store from "./store";

function findFormPropertiesIndex(prop, key) {
	const tagsIndex = prop.findIndex((item) => item.key === key);
	if (tagsIndex !== -1) {
		return tagsIndex;
	}
}

function getUserPermissions(resourceName, action) {
	if (store.getters.privilege == "admin") return true;

	let result = false;

	const permissionData = store.getters.currentRolePermissions;
	const resourcePermissions = permissionData.find(
		(item) => item.name === resourceName
	);
	if (resourcePermissions) {
		const hasPermission = resourcePermissions.can.includes(action);
		result = hasPermission;
	}
	return result;
}

export { findFormPropertiesIndex, getUserPermissions };
