// 初始化Vue
import { createApp } from "vue";
import ViewUIPlus from "view-ui-plus";
import App from "./App.vue";

// 引入核心插件
import router from "./router";
import store from "./store";

// 配置路由進度條
ViewUIPlus.LoadingBar.config({
	color: "#E3DA5A",
	failedColor: "#f0ad4e",
	height: 2,
});

router.beforeEach(async (to, from, next) => {
	const data = to.meta.requiredPermissionCheck;
	if (data) {
		// 檢查路由是否有讀取權限
		const result = await store.dispatch("getUser");
		if (result) {
			const hasPermission = getUserPermissions(data.permissoinName, "read");
			// 無讀取權限則導回首頁
			if (!hasPermission) {
				next({ name: "HomePage" });
			}
		}
		await store.dispatch("getLlicense");
	}

	ViewUIPlus.LoadingBar.start();
	next();
});

router.afterEach(() => {
	ViewUIPlus.LoadingBar.finish();
});

// 配置樣式
import "./style.css";
import "./theme/index.less";
import "view-ui-plus/dist/styles/viewuiplus.css";

//配置i18n
import i18n from "../src/i18n";

// 註冊自定義組件
import UpdateGroupDnspodInfoBtn from "@/components/Buttons/UpdateGroupDnspodInfoBtn";
import UpdateDomainCnameBtn from "@/components/Buttons/UpdateDomainCnameBtn";
import DeleteItemBtn from "@/components/Buttons/DeleteItemBtn";
import DialogMessage from "@/components/DialogMessage";

const app = createApp(App);

// 引入全局功能
import { getUserPermissions } from "@/utils";
app.config.globalProperties.$can = getUserPermissions;

import mitt from "mitt";
const emitter = mitt();
app.config.globalProperties.emitter = emitter;

import { ModuleRegistry } from "@ag-grid-community/core";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";

ModuleRegistry.registerModules([RangeSelectionModule]);

app
	.use(router)
	.use(store)
	.use(ViewUIPlus)
	.use(i18n)
	.component("UpdateGroupDnspodInfoBtn", UpdateGroupDnspodInfoBtn)
	.component("UpdateDomainCnameBtn", UpdateDomainCnameBtn)
	.component("DeleteItemBtn", DeleteItemBtn)
	.component("DialogMessage", DialogMessage)
	.mount("#app");
