<template>
    <div>
        <pre
            v-if="
                currentGroupData &&
                currentGroupData.id === currentGroupData.groupName &&
                privilege !== 'admin' &&
                !isNaN(remainingDays)
            "
            class="bg-yellow-400 text-red-600 text-center font-bold"
        >
            {{ `試用期 ${remainingDays} 天` }}</pre
        >
        <router-view />
    </div>
</template>

<script setup>
import { computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import axiosClient from "@/axiosInstance";

const store = useStore();
const currentGroupData = computed(() => store.getters.currentGroupData) || {};
const privilege = computed(() => store.getters.privilege) || "";
const currentUserCreatedDate = computed(() => store.getters.currentUserCreatedDate) || "";
const user = computed(() => store.state.user) || "";

// 計算剩餘天數的計算屬性
const remainingDays = computed(() => {
    const createdDate = new Date(currentUserCreatedDate.value);
    const today = new Date();
    const diffTime = Math.abs(today - createdDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return 30 - diffDays;
});

onMounted(async () => {
    if (
        currentGroupData &&
        currentGroupData.id === currentGroupData.groupName &&
        remainingDays.value === 0
    ) {
        const { groupName: groupId } = currentGroupData.value;
        try {
            await axiosClient.patch(
                `groups/${groupId}/users/${user.value.id}/updateDnsPodAndGroupStatus`,
                {
                    isEnabled: false,
                }
            );
            store.commit("deleteGroup", groupId);
        } catch (error) {
            console.error(error);
        }
    }
});

watch(remainingDays, async (newDays) => {
    store.commit("setRemainingDays", newDays);
});
</script>
