import { createI18n } from "vue-i18n/dist/vue-i18n.cjs";

import en from "view-ui-plus/dist/locale/en-US";
const en_US_Extend = require("./en-US/index.js");

import tw from "view-ui-plus/dist/locale/zh-TW";
const zh_TW_Extend = require("./zh-TW/index.js");

import store from "../store";

const messages = {
    "en-US": Object.assign({}, en, en_US_Extend),
    "zh-TW": Object.assign({}, tw, zh_TW_Extend),
    // "zh-CN": require("./zh-CN/index.js"),
};

const storeLanguage = store._state.data.selectedLanguage;
// 根據瀏覽器的語言來設定預設的語言
var userLang = storeLanguage || navigator.language || navigator.userLanguage;
// 預設使用者語言為en-US
userLang = Object.keys(messages).includes(userLang) ? userLang : "en-US";

const i18n = createI18n({
    allowComposition: true,
    globalInjection: true,
    legacy: false,
    locale: userLang,
    messages: messages,
});

export default i18n;
