import { createStore } from "vuex";
import axiosClient from "@/axiosInstance";

import VuexPersistence from "vuex-persist";
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});

const store = createStore({
    state() {
        return {
            languageItems: ["en-US", "zh-TW"],
            user: {
                email: "",
                username: "",
                password: "",
                privilege: "",
                tags: [""],
                description: "",
                groupRole: [
                    {
                        groupId: "",
                        roleId: "",
                    },
                ],
                mailServerIdentity: "",
                createdDate: "",
            },
            group: {},
            groupOptionData: [],
            groupsData: {},
            selectedGroupId: "",
            selectedLanguage: "",
            inviteSubmit: {
                success: false,
                id: "",
                username: "",
            },
            platformMaxQty: {},
            pageActionName: "",
            licenseToken: "",
            globalSearchKeyword: "",
            AdvancedSearchData: {
                isEmpty: false,
                encodedFilters: "",
                formattedData: [],
            },
            remainingDays: 0, // 試用期剩餘天數
        };
    },

    mutations: {
        setUser(state, newUser) {
            state.user = newUser;
        },
        setLlicense(state, newLicense) {
            state.platformMaxQty = newLicense;
        },
        clearUser(state) {
            state.user = {
                groupRole: [],
            };
        },
        setGroup(state, newGroup) {
            state.group = newGroup;
        },
        setGroupOptions(state, groupOptionData) {
            state.groupOptionData = groupOptionData;
        },
        setSelectedGroupId(state, selectedGroupId) {
            state.selectedGroupId = selectedGroupId;
        },
        setGroupData(state, { groupId, groupData }) {
            // 初始化 dnspodLineMap
            groupData.dnspodLineMap = {};

            // 合併 dnspodLineGroupList 和 dnspodLineList，並確保它們都是數組
            const lineList = [
                ...(Array.isArray(groupData.dnspodLineGroupList)
                    ? groupData.dnspodLineGroupList
                    : []),
                ...(Array.isArray(groupData.dnspodLineList) ? groupData.dnspodLineList : []),
            ];

            // 將 lineList 中的元素加入 dnspodLineMap
            lineList.forEach(({ LineId, Name }) => {
                groupData.dnspodLineMap[LineId] = Name;
            });

            // 將 groupData 儲存到 state 中
            state.groupsData[groupId] = groupData;
        },
        deleteGroup(state, groupId) {
            // 刪除指定的 groupId
            delete state.groupsData[groupId];

            // 如果被刪除的是當前選中的 groupId，將 selectedGroupId 清空
            if (state.selectedGroupId === groupId) {
                state.selectedGroupId = null;
            }
            // 同時將 currentGroupData 設置為 null
            state.currentGroupData = null;
        },
        setInviteSubmit(state, newInviteSubmit) {
            state.inviteSubmit = newInviteSubmit;
        },
        setSelectedLanguage(state, selectedLanguage) {
            state.selectedLanguage = selectedLanguage;
        },
        nowPageActionName(state, pageActionName) {
            state.pageActionName = pageActionName;
        },
        setLicenseToken(state, token) {
            state.licenseToken = token;
        },
        setGlobalSearchKeyword(state, keyword) {
            state.globalSearchKeyword = keyword;
        },
        clearGlobalSearchKeyword(state) {
            state.globalSearchKeyword = "";
        },
        setAdvancedSearchData(state, data) {
            state.AdvancedSearchData.isEmpty = data.isEmpty;
            state.AdvancedSearchData.encodedFilters = data.encodedFilters;
            state.AdvancedSearchData.formattedData = data.formattedData;
        },
        clearAdvancedSearchData(state) {
            state.AdvancedSearchData = { isEmpty: false, encodedFilters: "", formattedData: [] };
        },
        setRemainingDays(state, days) {
            state.remainingDays = days;
        },
    },

    actions: {
        // 獲取當前用戶資訊, 並更新於store
        async getUser(context) {
            // console.log("store 獲取用戶資訊");
            try {
                // 獲取當前用戶資訊
                const getCurrentUserResult = await axiosClient.get("user");

                // 如果獲取成功，則更新store中的用戶信息
                context.commit("setUser", getCurrentUserResult.data.data);

                return getCurrentUserResult;
            } catch (error) {
                // 處理請求失敗的情況
                if (error.response.status === 401) console.log("User not logged in");

                console.error("Error:", error);
            }
        },
        // 獲取當前license資訊, 並更新於store
        async getLlicense(context) {
            // console.log("store 獲取license資訊");
            try {
                if (store.getters.privilege == "admin") {
                    // 確認是否已存在 LicenseToken
                    const licenseResponse = await axiosClient.get("/license/tokens");
                    const { token } = licenseResponse.data.data;
                    context.commit("setLicenseToken", token);
                }

                const getCurrentLicenseResponse = await axiosClient.get("/license", {
                    params: { groupId: store.getters.currentGroupData.id },
                });

                if (!getCurrentLicenseResponse.data.success) return {};
                context.commit("setLlicense", getCurrentLicenseResponse.data.data.platformMaxQty);
                return getCurrentLicenseResponse;
            } catch (error) {
                console.error("An error occurred while fetching license:", error);
            }
        },
    },

    getters: {
        platformMaxQty(state) {
            return state.platformMaxQty;
        },
        privilege(state) {
            return state.user.privilege;
        },
        username(state) {
            return state.user.username;
        },
        groupRole(state) {
            return state.user.groupRole;
        },
        getInviteSubmit(state) {
            return state.inviteSubmit;
        },
        currentGroupData(state) {
            return state.groupsData[state.selectedGroupId];
        },
        currentUserCreatedDate(state) {
            return state.user.createdDate;
        },
        currentRolePermissions(state) {
            // 根據 selecteGroup 尋找對應的群组角色
            const selectedGroupRole = state.user.groupRole.find(
                (role) => role.groupId === state.selectedGroupId
            );

            // 獲取當前群組的角色權限
            const rolePermissions = selectedGroupRole ? selectedGroupRole.permissions : [];

            return rolePermissions;
        },
        getLanguageItems: (state) => {
            return state.languageItems;
        },
        licenseToken: (state) => {
            return state.licenseToken;
        },
        getSearchKeyword(state) {
            return state.globalSearchKeyword;
        },
        getAdvancedSearchData(state) {
            return state.AdvancedSearchData;
        },
        remainingDays: (state) => {
            return state.remainingDays;
        },
    },

    plugins: [vuexLocal.plugin],
});

export default store;
